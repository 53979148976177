function spbc_setCookie( cookies, value, expires ){

    if( typeof cookies === 'string' && ( typeof value === 'string' || typeof value === 'number' ) ){
        cookies = [ [ cookies, value, expires ] ];
    }

    // Cookies disabled
    if( +spbcPublic.data__set_cookies === 0 ){
        return;

    // Using traditional cookies
    }else if( +spbcPublic.data__set_cookies === 1 ){
        cookies.forEach( function (item, i, arr	) {
            var expires = typeof item[2] !== 'undefined' ? "expires=" + expires + '; ' : '';
            var ctSecure = location.protocol === 'https:' ? '; secure' : '';
            document.cookie = item[0] + "=" + encodeURIComponent(item[1]) + "; " + expires + "path=/; samesite=lax" + ctSecure;
        });

    // Using alternative cookies
    }else if( +spbcPublic.data__set_cookies === 2 ){

        // Using REST API handler
        if( +spbcPublic.data__set_cookies__alt_sessions_type === 1 ){
            spbc_sendRESTRequest(
                'alt_sessions',
                {
                    method: 'POST',
                    data: { cookies: cookies }
                }
            );

        }
        // Using AJAX request and handler
        // }else if( +spbcPublic.data__set_cookies__alt_sessions_type === 2 ) {
        //     apbct_public_sendAJAX(
        //         {
        //             action: 'apbct_alt_session__save__AJAX',
        //             cookies: cookies,
        //         },
        //         {
        //             apbct_ajax: 1,
        //             notJson: 1,
        //         }
        //     );
        // }
    }
}

function spbc_sendRESTRequest( route, params ) {

    var callback = params.callback || null;
    var data     = params.data || [];
    var method   = params.method || 'POST';

    jQuery.ajax({
        type: method,
        url: spbcPublic._rest_url + 'cleantalk-security/v1/' + route,
        data: data,
        beforeSend : function ( xhr ) {
            xhr.setRequestHeader( 'X-WP-Nonce', spbcPublic._rest_nonce );
        },
        success: function(result){
            if(result.error){
                console.log('Security by Cleantalk. Error: ' + (result.error || 'Unknown'));
            }else{
                if(callback) {
                    var obj = null;
                    callback(result, route, params, obj);
                }
            }
        },
        error: function(jqXHR, textStatus, errorThrown){
            if( errorThrown ) {
                console.log('SPBCT_REST_ERROR');
                console.log(jqXHR);
                console.log(textStatus);
                console.log('Security by Cleantalk plugin REST API error: ' + errorThrown + ' Please, contact Cleantalk tech support https://wordpress.org/support/plugin/security-malware-firewall/');
            }
        },
    });

}